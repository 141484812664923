.footerSection {
  width: 100%;
}

.footerInfo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  color: #ffffff;
  letter-spacing: 1px;
  padding: 0.5rem;
}

.footer a {
  color: inherit;
}

.footerAuthor,
.footerLinks {
  flex-direction: column;
}

.footerAuthor {
  padding: 0 0.5rem;
  line-height: 2;
}
.footerAuthor a {
  font-size: 0.7em;
  text-align: center;
  letter-spacing: 2px;
}
.footerLinks {
  line-height: 2.8;
}

.footerAuthor,
.footerLinks,
.socialIcons {
  display: inherit;
  align-items: center;
  justify-content: center;
}

.socialIcons a {
  color: #ffffff;
}

.footerLinks a {
  color: #ffffff;
  border-bottom: 1px solid #0d1117;
  transition: all ease-in-out 0.2s;
}

.footerLinks a:hover {
  cursor: pointer;
  border-bottom: 1px solid #ffffff;
}

.copyrightSection {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.footer p:nth-of-type(2) {
  margin-left: 15px;
}

.footerGithub {
  font-size: 32px;
  margin-right: 10px;
}

.footerGithub:hover {
  color: green;
}

.footerLinkedin:hover {
  color: #1f6feb;
}
.footerLinkedin {
  font-size: 30px;
}

@media (max-width: 600px) {
  .footerInfo {
    flex-direction: column;
  }

  .footerAuthor,
  .footerLinks {
    margin-bottom: 50px;
  }

  .footer a > p {
    font-size: 12px;
    color: #ffffff;
  }
}
