.header {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  min-height: 92vh;
  height: 100%;
}

.footer {
  display: flex;
  align-items: center;
  background-color: #0d1117;
  width: 100%;
  padding: 4rem 0;
}
