/* Styles for featured project section */
.projects {
  width: 100%;
}
.projectWrapper,
.mainGithubContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.5rem;
}
.projectWrapper {
  justify-content: space-evenly;
  margin: 3rem 0;
}
.mainGithubContainer {
  justify-content: center;
  width: 100%;
  margin: 3rem auto;
}
.projectContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin: 1.5rem;
  width: 450px;
  min-width: 250px;
  border: 1px solid #868788;
  border-radius: 5px;
}

.projectDescription {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 150px;
  width: 100%;
  border: none;
  margin: 3px;
}

.featuredLink {
  color: #141414;
}

.sourceCodeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: none;
}

.sourceCodeBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 50%;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1.1em;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 0.7rem;
  padding-right: 1rem;
  padding-left: 1rem;
  border: none;
  background: #1f6feb;
  color: #ffffff;
}

.projectsContainer {
  padding: 7rem 0.5rem;
  width: 100%;
  background: #0d1117;
}

.projectsContainerTitle,
.fullstackContainerTitle {
  margin-top: 0;
  font-weight: 500;
  line-height: 48px;
  font-size: 2.5em;
}

.projectsContainerTitle {
  text-align: center;
  margin-bottom: 2rem;
  color: #ffffff;
  letter-spacing: 1.2px;
}

.searchBarContainer {
  text-align: center;
  margin: 55px 0;
}

.searchBarForm {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 25px;
}

.searchBar {
  width: 400px;
  height: 35px;
  border: none;
  border-radius: 5px 0px 0px 5px;
  padding: 12px 0px 10px 12px;
  outline: none;
}

.searchBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  height: 35px;
  text-align: center;
  border: none;
  border-radius: 0px 5px 5px 0px;
  background: #ffffff;
}

.searchIcon {
  background: #ffffff;
  color: #868788;
}

.projectsContainerDescription {
  width: 75%;
  max-width: 1000px;
  padding: 0.5rem;
  text-align: center;
  margin: 0 auto;
}

.projectsContainerDescription p {
  letter-spacing: 1.2px;
  line-height: 30px;
  color: #868788;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  min-width: 200px;
  width: 420px;
  height: 200px;
  margin: 0.5rem;
  border-radius: 12px;
  border: 1px solid #868788;
  background: #0d1117;
}

.cardBody {
  display: inherit;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: stretch;
  height: 100%;
  min-width: 100%;
  padding: 15px;
}
.featuredProjectTitle {
  color: #e0e0e0;
  font-size: 1.4em;
  font-weight: 500;
  letter-spacing: 1.5px;
}

.featuredProjectDesc {
  font-size: 1em;
  color: #868788;
  letter-spacing: 1.2px;
}

.p4link {
  padding: 0;
  color: #0284fe;
  cursor: pointer;
  text-decoration: none;
}

.p4link:hover {
  text-decoration: underline;
  text-decoration-color: #0284fe;
}
.projectInfo {
  display: inherit;
  align-items: center;
  min-height: 70px;
  min-width: 100%;
}

.p3 {
  color: #868788;
  font-size: 1em;
  letter-spacing: 1px;
  margin: 0;
  padding: 0;
}

.projectCardFooter {
  display: inherit;
  align-items: center;
  color: #868788;
}

.githubRepoButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  height: 45px;
}

.githubRepoButton {
  border: none;
}

.githubRepoButton a {
  background: #0d1117;
  font-size: 15px;
  border: 1px solid #868788;
  border-radius: 5px;
  padding: 0.7rem;
  color: #ffffff;
  letter-spacing: 1.5px;
  animation: glow 1.5s ease-in-out infinite alternate;
  transition: all ease-out 0.3s;
}

@keyframes glow {
  from {
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  to {
    border: 1px solid #fff;
  }
}

.githubRepoButton a:hover {
  border: 1px solid #ffffff;
}

.circle {
  border-radius: 50%;
  padding: 0.1px 10px;
  margin-right: 10px;
}

/* media queries for all project components */

@media (max-width: 1035px) {
  .mainGithubContainer {
    width: 100%;
    margin: 0;
  }

  .projectContainer {
    max-width: 500px;
    text-align: center;
    margin: 0.5rem;
  }

  .par2 {
    max-width: 100%;
  }

  .project-tag {
    margin-left: 20px;
  }
}
@media (max-width: 600px) {
  .projectsContainerTitle {
    text-align: center;
  }

  .projectWrapper {
    padding: 0.5rem;
  }

  .projectContainer {
    padding: 0.5rem;
    width: 100%;
  }

  .projectsContainerTitle,
  .mainGithubContainer {
    padding: 0;
  }

  .cardContainer {
    height: auto;
  }

  .cardBody {
    padding: 16px;
  }

  .fullstackContainerTitle {
    padding-left: 0;
    margin-top: 50px;
    text-align: center;
  }

  .sourceCodeBtn {
    width: 100%;
    max-width: 300px;
  }

  .projectDetails {
    padding-left: 0px;
    width: 100%;
  }

  .projectsContainer {
    height: 100%;
    padding: 5em 0.5em;
  }

  .projectsContainerDescription p {
    font-size: 1.2em;
    width: 100%;
    letter-spacing: 2px;
    color: #868788;
  }
}
/* End of projects section */
