.overlay {
  z-index: 98;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.modal {
  z-index: 99;
  position: relative;
  width: 100%;
  max-width: 320px;
  max-height: 100%;
  margin: 0 auto;
}

.modalClose {
  position: absolute;
  top: -24px;
  right: 0;
  padding: 5px;
  border: 0;
  -webkit-appearance: none;
  background: none;
  color: white;
  cursor: pointer;
}

.modalBody {
  padding: 20px 24px;
  border-radius: 4px;
  background-color: white;
}
