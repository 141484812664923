.bannerContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin-top: 0.5rem;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.title h1 {
  font-size: 78px;
  font-weight: 500;
  color: #0d1117;
  line-height: 56px;
  padding: 0.5rem 0;
}

.title h2 {
  font-size: 25px;
  padding-left: 5px;
  padding-right: 5px;
  margin: 1.5rem 0;
  text-align: center;
  font-weight: 400;
  color: #505256;
  max-width: 52rem;
  line-height: 1.5em;
  letter-spacing: 1.2px;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.btnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 0px;
}

.aboutBtn,
.getInTouchBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 18rem;
  cursor: pointer;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 1.2rem;
  transition: all ease-in-out 0.4s;
  padding-right: 1rem;
  padding-left: 1rem;
}

.aboutBtn {
  border: none;
  background: #1f6feb;
  color: #ffffff;
  margin-right: 1rem;
}
.aboutBtn:hover {
  background: rgba(31, 111, 235, 0.8);
  box-shadow: 0 0 3px 0 rgba(8, 11, 14, 0.08),
    0 5px 5px -1px rgba(8, 11, 14, 0.1);
}

.getInTouchBtn {
  border: 1px solid #e1e4e8;
  background: transparent;
  color: #505256;
}

.getInTouchBtn:hover {
  background: rgba(241, 242, 244, 0.2);
  box-shadow: 0 0 3px 0 rgba(8, 11, 14, 0.06),
    0 5px 5px -1px rgba(8, 11, 14, 0.1);
}

@media (max-width: 600px) {
  .bannerContainer {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 0;
  }
  .btnContainer {
    flex-direction: column;
  }
  .aboutBtn {
    margin-right: 0;
    margin-bottom: 1.5em;
  }

  .title {
    margin-bottom: 0;
    text-align: center;
  }
  .title h1 {
    font-size: 3em;
  }
  .title h2 {
    font-size: 1.2em;
  }
  .aboutBtn,
  .getInTouchBtn {
    font-size: 1em;
    width: 90%;
    max-width: 420px;
  }
}
