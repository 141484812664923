.contactMessageContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  width: 50%;
  padding-top: 4rem;
}

.contactMessageContainer h2 {
  font-size: 1.8em;
  font-weight: 600;
  letter-spacing: 2px;
}

.contactMessageContainer h1 {
  margin: 15px 0px;
  line-height: 1.2;
  letter-spacing: 1.2px;
  font-size: 2.5em;
  font-weight: 500;
  color: #0d1117;
}
.contactMessageContainer p {
  font-size: 1em;
  letter-spacing: 1.2px;
  color: #505256;
}

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 0;
  width: 56%;
  margin: 0rem auto 5rem auto;
  height: 65%;
}

.formContainer label {
  font-size: 1.1em;
  letter-spacing: 2px;
}

.formContainer input {
  width: 100%;
  margin: 15px 0;
  padding: 8px 5px 8px 20px;
  border: 1px solid #e1e4e8;
  border-radius: 5px;
}

.formContainer button {
  padding: 0.8rem;
  border: none;
  border-radius: 5px;
  background: #1f6feb;
  color: #ffffff;
  font-size: 1.3em;
  font-weight: 500;
  letter-spacing: 3px;
  cursor: pointer;
}

.formContainer textarea,
.formContainer input {
  background: #ffffff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
}

.formContainer textarea:focus,
.formContainer input:focus {
  outline: none;
}

.formContainer textarea {
  width: 100%;
  margin: 15px 0;
  border: 1px solid #e1e4e8;
  border-radius: 3px;
  resize: none;
  padding-top: 8px;
  padding-left: 20px;
}

@media (max-width: 1310px) {
  .contactMessageContainer {
    width: 70%;
  }
  .formContainer {
    width: 70%;
  }
}

@media (max-width: 900px) {
  .contactMessageContainer {
    width: 90%;
  }
  .formContainer {
    width: 90%;
  }
}
