.NavbarItems {
  background: rgb(255, 255, 255);
  height: 8vh;
  display: flex;
  align-content: center;
  font-size: 1.2rem;
  margin: 0 auto;
  width: 90%;
}

/* styles for text name of logo */
.navbarLogo {
  display: flex;
  color: #0d1117;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  font-size: 32px;
  font-weight: 500;
}

.navbarLogo span {
  font-size: 20px;
  margin-left: 4px;
}

.navMenu {
  display: flex;
  flex: 1;
  list-style: none;
  align-items: center;
  justify-content: flex-end;
}

.navLinks {
  color: #0d1117;
  font-size: 0.75em;
  font-weight: 500;
  margin-right: 40px;
  text-decoration: none;
  padding: 0.2rem;
  border-bottom: 1px solid #ffffff;
  cursor: pointer;
  letter-spacing: 1px;
  transition: all 0.3s ease-in-out;
}

.navLinks:hover {
  cursor: pointer;
  border-bottom: 1px solid #0d1117;
}

.contactLink {
  color: #1f6feb;
  padding: 0.2rem 1rem;
  font-size: 16px;
  font-weight: 500;
  background: #ffffff;
  border: 1px solid #1f6feb;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.contactLink a {
  cursor: pointer;
  color: #ffffff;
}

.contactLink:hover {
  color: #ffffff;
  background: #1f6feb;
}

.activeLink {
  color: #1f6feb;
}

.faBars {
  color: #0d1117;
}

.menuIcon {
  display: none;
}

@media screen and (max-width: 600px) {
  .NavbarItems {
    position: relative;
    width: 100%;
    margin: 0 auto;
  }

  .navMenu {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    flex: 1;
    width: 100%;
    height: 90vh;
    padding-bottom: 8rem;
    position: absolute;
    top: 50px;
    left: -100%;
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }

  .navMenu.active {
    background-color: #ffffff;
    height: 100vh;
    left: 0;
    transition: all 0.3s ease-in-out;
    z-index: 900;
    overflow: hidden;
  }

  .navLinks {
    text-align: center;
    margin-right: 0;
    padding: 1rem;
    display: table;
    transition: all 0.5s ease;
  }

  .navbarLogo {
    flex: 1;
    justify-content: center;
  }
  .navLinks:hover {
    color: #0d1117;
    border-radius: 0;
  }

  .contactLink {
    color: #1f6feb;
    padding: 0.6rem 5rem;
  }
  /* styles to position fontawesome icons navbar */
  .menuIcon {
    display: flex;
    flex: 1;
    margin-right: 30px;
    align-items: center;
    justify-content: flex-end;
    font-size: 1.2rem;
    cursor: pointer;
  }

  /* font awesome X icon to close mobile menu */
  .faTimes {
    color: #0d1117;
  }

  .navLinksMobile:hover {
    color: #0d1117;
    background: #ffffff;
    transition: 250ms;
  }
}

@media (max-width: 600px) {
  .NavbarItems {
    height: 8vh;
  }
  .navbarLogo {
    font-size: 22px;
  }
}
@media (max-width: 300px) {
  .menuIcon {
    margin-right: 1.5rem;
  }
  .navbarLogo {
    margin-left: 1rem;
  }
}
