/* Styles for single project page */

.singleProjectContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 1rem;
  margin: 6rem auto;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border: 1px solid #f7f8f9;
  border-radius: 8px;
  overflow: hidden;
}

.projectDetails {
  display: flex;
  flex: 0.7;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.singleProjectTitle {
  text-align: center;
}
.singleProjectDesc,
.singleProjectTitle {
  width: 100%;
  padding: 0.5rem;
}

.singleProjectLinks {
  display: inherit;
  width: 100%;
  justify-content: space-evenly;
}

.singleProjectTitle h1 {
  font-size: 3em;
  color: #141414;
  letter-spacing: 2px;
}

.singleProjectDesc {
  justify-content: center;
  min-width: 200px;
  max-width: 500px;
}

.singleProjectDesc p {
  font-size: 1em;
  font-weight: 400;
  letter-spacing: 1.8px;
  color: #141414;
  padding-bottom: 1.5rem;
}

.singleProjectBackLink {
  align-self: center;
}

.singleProjectBackLink:hover {
  color: #1f6feb;
}

.singleProjectLink {
  display: flex;
  justify-content: center;
  align-self: center;
  color: #0d1117;
  font-weight: 500;
  width: 50%;
  letter-spacing: 1px;
  border: 1px solid #0d1117;
  border-radius: 5px;
  padding: 5px;
  margin: 0 0 15px 0;
  transition: all ease-in-out 0.2s;
}

.singleProjectLink:hover {
  background-color: #0284fe;
  border: 1px solid #0284fe;
  color: #ffffff;
}

/* media queries for all project components */
@media (min-width: 1201px) {
  .imageContainer {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}
@media (max-width: 1200px) {
  .singleProjectContainer {
    flex-direction: column;
  }
  .imageContainer {
    margin-bottom: 100px;
  }
}

@media (max-width: 1035px) {
  .mainGithubContainer {
    width: 100%;
    margin: 0;
  }
  .singleProjectContainer {
    margin-top: 50px;
    border: none;
  }
}
@media (max-width: 900px) {
  .projectContainer {
    min-width: 80%;
    text-align: center;
    margin: 0.5rem;

    min-width: 200px;
  }

  .singleProjectLinks {
    width: 100%;
  }

  .singleProjectLink {
    width: 100%;
  }
  .par2 {
    max-width: 100%;
  }

  .project-tag {
    margin-left: 20px;
  }
}
@media (max-width: 500px) {
  .singleProjectContainer {
    padding: 0.5rem;
  }

  .singleProjectLinks {
    flex-direction: row;
  }
}
@media (min-width: 200px) {
  .imageContainer {
    min-width: 200px;
    margin-bottom: 5rem;
  }

  .singleProjectTitle {
    margin-top: 1rem;
  }
  .singleProjectTitle h1 {
    line-height: 1.2;
  }

  .singleProjectDesc p {
    padding-bottom: 15px;
  }

  .singleProjectLinks {
    flex-direction: column;
    width: 100%;
    margin-top: 0.5rem;
  }

  .singleProjectBackLink {
    margin-top: 0.5rem;
  }
}
/* End of projects section */
